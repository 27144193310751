import { View, Text, Dimensions, TouchableOpacity, TextInput, FlatList, Image, ActivityIndicator } from 'react-native'
import React, {useState, useEffect} from 'react'
const {width, height} = Dimensions.get('screen');
import { useNavigation, useRoute } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons'; 
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from './src/graphql/queries';
import * as mutations from './src/graphql/mutations';
import { getUserData } from './src/graphql/queries';
import * as DocumentPicker from 'expo-document-picker';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from './config';
import uuid from 'react-native-uuid';

const Details = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const [repoAcc, setRepoAcc] = useState(false)
    const [deceased, setDeceased] = useState(false)
    const [decOpt, setDecOpt] = useState(false)
    const [userInfo, setUserInfo] = useState();
    const [spinning, setSpinning] = useState(false);
    const [imgUrlOne, setImgUrlOne] = useState();
    const [keyToUser, setKeyToUser] = useState(false)

    //customer 
    const [idNumber, setIdNumber] = useState('');
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');

    
    const getUserInfo = async()=>{

        try{
            await API.graphql(graphqlOperation(getUserData, { id: route.item.id})).then((ef)=> {
            const userd = ef.data.getUserData
      
            setUserInfo(userd)
      
          }).catch((ee)=> {
            console.error(ee)
          })
      
      } catch (e){
        console.error(e)
        
      }

    }

    useEffect(() => {
        getUserInfo()
      }, [userInfo])

       
    const settingDeceased = async()=> {

        setSpinning(true)

        const authdata = await Auth.currentAuthenticatedUser()
        const authedId = authdata.attributes.sub

        const inputData ={
            id: route.item.id,
            deceased: true,
            MHCId: authedId      
        }

        try {
            await API.graphql({query: mutations.updateUserData, variables: {input: inputData}}).then((ee)=> {
                setDeceased(true), 
                setDecOpt(false),
                setSpinning(false)
              })
        } catch (error) {
            console.error(error)
        }
        

    }

    const settingKey = async()=> {

        setSpinning(true)

        const authdata = await Auth.currentAuthenticatedUser()
        const authedId = authdata.attributes.sub

        const newId = uuid.v4();
       


        const inputData ={
            id: route.item.id,
            AccessKey: newId      
        }

        try {
            await API.graphql({query: mutations.updateUserData, variables: {input: inputData}}).then((ee)=> {
                setDeceased(true), 
                setDecOpt(false),
                setSpinning(false)
              })
        } catch (error) {
            console.error(error)
        }
        

    }

    const settingKeyToUser = async()=> {

        setSpinning(true)

        const authdata = await Auth.currentAuthenticatedUser()
        const authedId = authdata.attributes.sub

        const newId = uuid.v4();
       


        const inputData ={
            id: route.item.id,
            AccessKeyUserId: idNumber,
            AccessKeyUserFirstName: firstName,
            AccessKeyUserSurname: surname      
        }

        try {
            await API.graphql({query: mutations.updateUserData, variables: {input: inputData}}).then((ee)=> {
                setKeyToUser(false)
                navigation.navigate('ID')
              })
        } catch (error) {
            console.error(error)
        }
        

    }

  
    


  return (
    <View>
       <View style={{height: height / 2, backgroundColor: '#fcfcfc', width: height/ 2, borderRadius: 10, padding: 30}}> 
            
           <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Image source={{uri: route.item.image}} style={{height: 150, width: 150, borderRadius: 100}}/>
                <View style={{marginLeft: 15}}>
                    <Text style={{fontSize: 20, fontWeight: 'bold', color: '#0f2838'}}>{route.item.firstName} {route.item.surname} </Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>{route.item.idNumber}</Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>{route.item.email || `info@sundaycreatives.co.za`}</Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>Registered : {String(route.item.createdAt).slice(0,10) || `--`}</Text>
                    <Text style={{fontSize: 12, color: '#0f2838'}}>Updated : {String(route.item.updatedAt).slice(0,10) || `--`}</Text>
                </View>
           </View>
           <View style={{marginTop: 20}}>
                <Text style={{fontWeight: 'bold', alignSelf: 'center'}}>Options</Text>
           </View>

           <View style={{marginTop: 25,flexDirection: 'row', justifyContent: 'space-evenly'}}>
                
                <View style={{width: '40%'}}>
                {userInfo?.deceased ? 
                <View>
                    {userInfo?.DeathCertificate ? 
                    <View style={{width: '100%'}}>

                        
                    {userInfo?.AccessKey ? <TouchableOpacity onPress={()=> setKeyToUser(true)}
                    style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                    >
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>Assign Key To User</Text>
                        <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <AntDesign name="arrowright" size={18} color="white" />
                        </View>    
                    </TouchableOpacity>  
                    :
                    <TouchableOpacity onPress={()=> settingKey()}
                    style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                    >
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>Generate Access Key</Text>
                        <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <AntDesign name="arrowright" size={18} color="white" />
                        </View>  
    
                        <View style={{position: 'absolute', top: 30, left: 30}}>
                            {spinning && <ActivityIndicator size={'large'} color={'red'} />}
                        </View>  
                        </TouchableOpacity>
                }
                   
                      
                    </View>
                    :

                    <View style={{width: '100%'}}>

                        
                    {userInfo?.AccessKey ? <TouchableOpacity onPress={()=> setKeyToUser(true)}
                    style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                    >
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>Assign Key To User</Text>
                        <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <AntDesign name="arrowright" size={18} color="white" />
                        </View>    
                    </TouchableOpacity>  
                    :
                    <TouchableOpacity onPress={()=> settingKey()}
                    style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                    >
                        <Text style={{fontWeight: 'bold', fontSize: 16}}>Generate Access Key</Text>
                        <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                            <AntDesign name="arrowright" size={18} color="white" />
                        </View>  
    
                        <View style={{position: 'absolute', top: 30, left: 30}}>
                            {spinning && <ActivityIndicator size={'large'} color={'red'} />}
                        </View>  
                        </TouchableOpacity>
                }
                   
                      
                    </View>  
                    }
                    

                </View>
                 : 
                <TouchableOpacity onPress={()=> setDecOpt(true)} 
                style={{height: 80, width : '100%', marginBottom: 10, backgroundColor: 'rgba(40, 164, 247, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: 16}}>Set Deceased</Text>
                    <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                        <AntDesign name="arrowright" size={18} color="white" />
                    </View>    
                </TouchableOpacity> } 
                </View>
               

                <TouchableOpacity onPress={()=> setRepoAcc(true)}
                style={{height: 80, width : '40%', marginBottom: 10, backgroundColor: 'rgba(247, 88, 40, 0.2)', borderRadius: 10, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', paddingHorizontal: 15}}
                >
                    <Text style={{fontWeight: 'bold', fontSize: 16}}>Report Account</Text>
                    <View style={{width: 25, height: 25, borderRadius: 30, backgroundColor: 'green', alignItems: 'center', justifyContent: 'center'}}>
                        <AntDesign name="arrowright" size={18} color="white" />
                    </View>    
                </TouchableOpacity>
           </View>

                


      </View>

      {repoAcc && 

        <View style={{position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%'}}>
            <View style={{height: '40%', backgroundColor: '#fcfcfc', width: '40%', borderRadius: 10, padding: 30}}>
                <Text style={{fontWeight: 'bold', fontSize: 14, textAlign: 'justify'}}>Are you sure you want to report this account ?</Text>

                <View style={{marginTop: 15}}>
                    <TouchableOpacity onPress={()=> {setRepoAcc(false)}} style={{height: 40, width: '100%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Yes, I'm Sure</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> setRepoAcc(false)} style={{height: 40, width: '100%', marginTop: 15, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                        <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>No, Cancel</Text>
                    </TouchableOpacity>
                </View>

            </View>
    
        </View>

        }

            {decOpt && 

            <View style={{position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%'}}>
                <View style={{height: '40%', backgroundColor: '#fcfcfc', width: '40%', borderRadius: 10, padding: 30}}>
                    <Text style={{fontWeight: 'bold', fontSize: 14, textAlign: 'justify'}}>Are you sure you want to set as deceased t ?</Text>

                    <View style={{marginTop: 15}}>
                        <TouchableOpacity onPress={()=> { settingDeceased()}} style={{height: 40, width: '100%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                            <View style={{position: 'absolute', left: 5}}>
                                {spinning && <ActivityIndicator color={'#fcfcfc'} size={'small'} />}
                            </View>
                            <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Yes, I'm Sure</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> { setDecOpt(false)}} style={{height: 40, width: '100%', marginTop: 15, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                            <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>No, Cancel</Text>
                        </TouchableOpacity>
                    </View>

                </View>

            </View>

            }

{keyToUser && 

<View style={{position: 'absolute', zIndex: 9000, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%'}}>
    <View style={{height: '95%', backgroundColor: '#fcfcfc', width: '80%', borderRadius: 10, padding: 30}}>
        <Text style={{fontWeight: 'bold', fontSize: 14, textAlign: 'justify'}}>Add the key to a user</Text>

        <View>

            <View style={{marginTop: 20}}>
                <Text style={{marginBottom: 15}}>ID Number</Text>
                <TextInput placeholder='90021547845**' value={idNumber} onChangeText={setIdNumber} style={{width: '80%', height: 50, borderColor: 'gray', borderWidth: 1, paddingLeft: 20}}/>
            </View>

            <View style={{marginTop: 5}}>
                <Text style={{marginBottom: 15}}>First Name</Text>
                <TextInput placeholder='First Name' value={firstName} onChangeText={setFirstName} style={{width: '80%', height: 50, borderColor: 'gray', borderWidth: 1, paddingLeft: 20}}/>
            </View>

            <View style={{marginTop: 5}}>
                <Text style={{marginBottom: 15}}>Surname</Text>
                <TextInput placeholder='Surname' value={surname} onChangeText={setSurname} style={{width: '80%', height: 50, borderColor: 'gray', borderWidth: 1, paddingLeft: 20}}/>
            </View>

        </View>

        <View style={{marginTop: 15}}>
            <TouchableOpacity onPress={()=> {settingKeyToUser()}} style={{height: 50, width: '80%', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Assign</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={()=> setKeyToUser(false)} style={{height: 50, width: '80%', marginTop: 15, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 10}}>
                <Text style={{fontWeight: 'bold', color: '#fcfcfc'}}>Cancel</Text>
            </TouchableOpacity>
        </View>

    </View>

</View>

}

    </View>
  )
}

export default Details