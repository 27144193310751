import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Image, ActivityIndicator, TextInput, Animated, FlatList, ImageBackground } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Feather, MaterialCommunityIcons } from '@expo/vector-icons'; 
import Header from '../Components/Header';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '../src/graphql/queries';
import * as mutations from '../src/graphql/mutations';
const {width, height} = Dimensions.get('screen');
import { useStateContext } from '../src/contexts/ContextProvider';
import { useNavigation } from '@react-navigation/native';
import LoginFlow from '../Components/LoginFlow';
import PaystackPop from '@paystack/inline-js';
import { getUserData } from '../src/graphql/queries';

const Payment = () => {

    const {counting, setCounting, authed, setAuthed} = useStateContext();
    const navigation = useNavigation();
    
    const [spinning, setSpinning] = useState(false);
    const [showLoaded, setShowLoaded] = useState(false);
    const [cartProducts, setCartProducts] = useState([]);
    const [userData, setUserData] = useState()

    const ourOrange = '#FF4F00';

    const emaila = userData?.email
    const firstName = userData?.firstName

    const fetchingTickets = async()=> {

  
        if(authed){
          const userIdd = await Auth.currentAuthenticatedUser({bypassCache: true})
    
            try {
                const todoData = await API.graphql({query: queries.listCarts, variables:  
                    {filter :   { userId: 
                            {eq: 
                                (userIdd?.attributes.sub)
                            },
                            }
                     }
                })
                    const todos = todoData.data.listCarts.items
                    setCartProducts(todos)
            } catch (error) {
                console.error(error)
                navigation.goBack()
            }
    
         

          try {
            const userSub =  userIdd?.attributes.sub  
            await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((eff)=> {
                const thedata = eff.data.getUserData

                setUserData(thedata)

            })

          } catch (error) {
            console.error(error)
            navigation.goBack()
          }




        }
    
    
        
    }
    
    useEffect(()=> {
        fetchingTickets();
    }, [cartProducts, authed])





    if(!authed){
        return (
          <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
            <View style={{padding: 20}}>
              <TouchableOpacity onPress={()=> navigation.goBack()}>
                <Feather size={30} name='arrow-left' />
              </TouchableOpacity>
            </View>
            <LoginFlow/>
          </View>
        )
        
         
      }


      let totalPrice = 0;

    totalPrice = cartProducts?.reduce((summedPrice, product) => 
    (summedPrice + (product?.amount || 0) * product?.qty ), 
    0);

    const serviceFee = totalPrice * 0.04    
    const bookingFee = 5.50

    const paywithpaystack = (e) => {

       

        const paystack = new PaystackPop()
        paystack.newTransaction({
            // key: "pk_test_deff22609582e8a41f931f8140f48728bf0b195a",
            key: "pk_live_18e1b28183b4c600212f2d0cf2167d03e03f7c60",
            amount: (totalPrice + serviceFee + bookingFee)*100,
            email: emaila,
            firstname: firstName,
            lastname: '--',
            onSuccess(transaction){
                let message = `Payment Complete ! Reference ${transaction.reference}`
                createOrder({payRef: transaction.reference})
            },
            oncancel(){
                alert('You have cancelled the transaction')
            }
        })
    }


    const createOrder  = async({payRef})=> {


        const userData = await Auth.currentAuthenticatedUser();

        try {

            await Promise.all( 
                cartProducts?.map((e) => {
                 

                    const senda = {
                        qty: e?.qty,
                        eventId: e?.eventId,
                        seatId: e?.seatId,
                        vendorId: e?.vendorId,
                        image: e?.image,
                        orderAmount: e?.amount,
                        title: e?.title,
                        userId: userData.attributes.sub,
                        campaignId: e?.campaignId,
                        ownerId: e?.ownerId || '1',
                        eventDate: '',
                        eventTime: '',
                        eventLocation: '',
                        paymentStatus: 'Paid',
                        paymentReference: payRef,
                        fullName: firstName,
                        country: 'South Africa',
                        active: 'true',
                        email: emaila,
                        
                    }

                    

                    const deletee = {
                        id: e?.id,
                     
                    }

                    const blueza = async() => {

                        await API.graphql(graphqlOperation(mutations.createOrder, { input: senda})).then((ef)=>{

                            API.graphql(graphqlOperation(getUserData, { id: e?.vendorId})).then((ef)=> {
                                const userd = ef.data.getUserData

                                
                                const userUpd = {
                                    id: e?.vendorId,
                                    pendingBalance: ((userd?.pendingBalance || 0 ) + (e?.amount|| 1) )
                                }        
                                
                                
                                API.graphql(graphqlOperation(mutations.updateUserData, {input: userUpd})).then((esdd)=> {
                                    API.graphql(graphqlOperation(mutations.deleteCart, { input: deletee})).then((efd)=>{
                                        navigation.navigate('MyTickets')
                                    })  
                                })
                            
                              })
                           
                        })}  


                        blueza();

                }
 
            )
            ).then((ee)=> {
                alert('order complete')
            })
        } catch (error) {
            console.error(error)
         }

    }


  return (
    <View style={{flex: 1, backgroundColor: '#edf1f2'}}>
      <View style={{zIndex: 19000}}>
         <Header/>
        </View>

        {authed &&
            <View style={{height: height / 2, width: width / 3, backgroundColor: '#fcfcfc', padding: 25, marginTop: 5, alignSelf: 'center'}}>
                    <Text style={{fontSize: 16, fontWeight: 'bold', marginBottom: 15, marginLeft: 0}}>Payment Summary</Text>

                    <View style={{marginTop: 20}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Cart Amount</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(totalPrice).toFixed(2)}</Text>
                            </View>
                        </View>

                        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Service Fee</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(serviceFee).toFixed(2)}</Text>
                            </View>

                        
                        </View>

                        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Booking Fee</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(bookingFee).toFixed(2)}</Text>
                            </View>

                        
                        </View>

                        <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
                            <View style={{width: '40%'}}>
                                <Text style={{fontSize: 13}}>Order Amount</Text>
                            </View>

                            <View style={{marginLeft: 30}}>
                                <Text style={{fontSize: 13}}>R{Number(serviceFee + totalPrice + bookingFee).toFixed(2)}</Text>
                            </View>

                        
                        </View>

                        <View style={{marginTop: 80}}>
                            <TouchableOpacity onPress={()=> paywithpaystack( )} style={{height: 45, width: '50%', borderRadius: 5, flexDirection: 'row', backgroundColor: 'green', alignItems: 'center', justifyContent: 'center', alignSelf: 'center'}}>
                                <Feather name= 'lock' color={'white'} size={15} />
                                <Text style={{fontWeight: 'bold', fontSize: 12, color: 'white', marginLeft: 15}}>Pay With PayStack</Text>
                            </TouchableOpacity>

                            <Image source={{uri: 'https://uploads-ssl.webflow.com/5edfc79600691067acf835bd/6079706c752a09007a6ebea3_Paystack_Logo.png'}}  style={{height: 45, width: 100, resizeMode: 'center', alignSelf: 'center', marginTop: 5}}/>
                        </View>


                    </View>

            </View>
        }




    </View>
  )
}

export default Payment

const styles = StyleSheet.create({})