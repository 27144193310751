import { StyleSheet, Text, View, Dimensions, TouchableOpacity, Animated, ImageBackground, FlatList, ScrollView, Image } from 'react-native'
import React, {useState, useEffect} from 'react';
import Header from './Components/Header';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { Feather, FontAwesome, Ionicons, AntDesign, MaterialIcons, Entypo } from '@expo/vector-icons'; 
import { FlashList } from "@shopify/flash-list";
import * as queries from './src/graphql/queries';
import { Video, ResizeMode } from 'expo-av';


import { useStateContext } from './src/contexts/ContextProvider';

import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getUserData } from './src/graphql/queries';

const {width, height} = Dimensions.get('screen')



const HomeMain = () => {
  const {counting, setCounting, authed, setAuthed, authedUser, setAuthedUser} = useStateContext();
    const imageBack = 'https://images.unsplash.com/photo-1633465784393-d0c2da7cc9cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'
    const navigation = useNavigation();
    const joyus = 'https://lh3.googleusercontent.com/cWmb1yIGgUNIEYfX6Z8Lsg-X-5m4VjOt7nCS6UvyBy54Xad-_HRAu_wi6VbaTE4t-mPtb7vSPP9tAvAaaRSgafz5Zn16VfYBWw'
    const ourOrange = '#FF4F00';

    const scrollX = new Animated.Value(0);
    let position  = Animated.divide(scrollX, width)
    const [userInfo, setUserInfo] = useState();

    const [banners, setBanners] = useState([]);
    const [secOne, setSecOne] = useState([]);
    const [secTwo, setSecTwo] = useState([]);
    const [secThree, setSecThree] = useState([]);
    const [status, setStatus] = React.useState({});

    const video = React.useRef(null);

  const checkAuth = async()=>{


    try {
      await Auth.currentAuthenticatedUser().then((ef)=> {
        return
      })
    } catch (error) {
      console.error(error),
      setAuthed(false)
    }



    const userAuthenticated =  await Auth.currentAuthenticatedUser();
    const userSub = userAuthenticated.attributes.sub;

    
    try{
      await API.graphql(graphqlOperation(getUserData, { id: userSub})).then((ef)=> {
      const userd = ef.data.getUserData

        setAuthedUser({
          email: userd.email,
          firstName: userd.firstName
        })

      setUserInfo(userd),
      setAuthed(true)

      return
    }).catch((ee)=> {
      console.error(ee),
      setAuthed(false)
    })

} catch (e){
  console.error(e),
  setAuthed(false)
}

  }

  const listingBanners = async() => {
      

    const allTodos = await API.graphql({query: queries.listCampaigns, variables: 
        {
        filter : { and: [ 
            {active: {eq: true}},
            {approved: {eq: true}},
            {banner: {eq: true}},
            
        ]}
    } });
    
    const todos = allTodos.data.listCampaigns.items
    setBanners(todos)
    

    const allSecone = await API.graphql({query: queries.listCampaigns, variables: 
      {
      filter : { and: [ 
          {active: {eq: true}},
          {approved: {eq: true}},
          {secOne: {eq: true}},
          
      ]}
  } });
  
  const seconedata = allSecone.data.listCampaigns.items
  setSecOne(seconedata)


  const allSecTwo = await API.graphql({query: queries.listCampaigns, variables: 
    {
    filter : { and: [ 
        {active: {eq: true}},
        {approved: {eq: true}},
        {secTwo: {eq: true}},
        
    ]}
} });

const secTwodata = allSecTwo.data.listCampaigns.items
setSecTwo(secTwodata)


const allSecThree = await API.graphql({query: queries.listCampaigns, variables: 
  {
  filter : { and: [ 
      {active: {eq: true}},
      {approved: {eq: true}},
      {secThree: {eq: true}},
      
  ]}
} });

const secThreedata = allSecThree.data.listCampaigns.items
setSecThree(secThreedata)



}

  useEffect(() => {
    checkAuth()
    listingBanners()
       
  }, [userInfo, banners])



    const categoryList = [
      {
        id: 1,
        name: 'Comedy'
      },
      {
        id: 2,
        name: 'Festival'
      },
      {
        id: 3,
        name: 'Music'
      },
      {
        id: 4,
        name: 'Church'
      },
      {
        id: 5,
        name: 'Fashion'
      },
      {
        id: 6,
        name: 'Bus'
      },
      {
        id: 7,
        name: 'Soccer'
      },
      {
        id: 8,
        name: 'Rugby'
      },{
        id: 9,
        name: 'Cricket'
      },{
        id: 10,
        name: 'Kids'
      },{
        id: 11,
        name: 'Lifestyle'
      },{
        id: 12,
        name: 'Theatre'
      },{
        id: 13,
        name: 'Tourism'
      },{
        id: 14,
        name: 'Faith'
      },{
        id: 15,
        name: 'Seminar'
      },{
        id: 16,
        name: 'Workshop'
      },{
        id: 17,
        name: 'Charity'
      },{
        id: 18,
        name: 'Community'
      },{
        id: 19,
        name: 'Health'
      }
    ]





    const renderBanner = ({item}) => {
      return(
    <ImageBackground imageStyle={{borderRadius: 0}} style={{width: width - (width * 0), elevation: 0, height: height / 1.8, borderRadius: 0}}   source={{uri: item?.image}} >
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: '40%', padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>{item?.name}</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Feather name= 'info' color={'white'} size={15} />
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 10}}>FROM R{Number(item?.minAmount).toFixed(2)}</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity onPress={()=> navigation.navigate('EventDetails', {item: item})} style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>See Event</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
     </ImageBackground>
      )
    }


    const showBanner = () => {

      return(
        <View>
          <FlatList
                    data={banners}
                    keyExtractor={(item, index) => 'key'+index}
                    horizontal
                    pagingEnabled
                    scrollEnabled
                    snapToAlignment='center'
                    scrollEventThrottle={16}
                    decelerationRate={"normal"}
                    showsHorizontalScrollIndicator={false}
                    renderItem={renderBanner}
                    onScroll = {Animated.event(
                        [{nativeEvent: {contentOffset: {x: scrollX}}},
                        
                        ], {useNativeDriver: false}
                    )}


                 />

                 <Animated.View style={[styles.dotView,{marginTop: 15, backgroundColor: 'rgba(0,0,0,0.9)', position: 'absolute', zIndex: 9000, top: 40, right: 20}]}>
                    {banners.map((_, i) => {
                      let opacity = position.interpolate({
                            inputRange: [i - 1, i, i + 1],
                            outputRange: [0.2, 1, 0.2],
                            extrapolate: 'clamp'
                        })

                        return(
                            <Animated.View key={i}
                            style={{opacity, height: 7, width: 7, backgroundColor: 'black', margin: 8, borderRadius: 5, marginTop: -30}}
                              />
                        )
                    })}
                 </Animated.View>

                 </View>
      )
      

    }

    const imageCat = 'https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80'

    const imageHed = 'https://images.unsplash.com/photo-1620503374956-c942862f0372?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'
    const secImage = 'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80'


    const renderEventList = ({item})=> {
      return(
        <TouchableOpacity style={{marginRight: 10}} onPress={()=> navigation.navigate('EventDetails', {item: item})}>
            <ImageBackground source={{uri: item.image}} imageStyle={{borderRadius: 5}} style={{width: 300, height: 300}}>

            </ImageBackground>
            <Text style={{fontSize: 16, marginTop: 10}}>{String(item.name).slice(0,28)}</Text>
            <Text style={{fontSize: 12, color: 'gray'}}>From R{Number(item.minAmount).toFixed(2)}</Text>
        </TouchableOpacity>
      )
    }


    const renderCategory = ({item})=> {
      return(
        <TouchableOpacity onPress={()=> navigation.navigate('CategorySearch', {cat: item.name})} style={{height: 200, marginRight: 15, width: 200, borderWidth: 0, alignItems: 'center', justifyContent: 'center'}}>
          <View style={{height: 170, width: '100%'}}>
            <Image source={{uri: imageCat}} style={{height: '100%', width: '100%', borderRadius: 10}} />
          </View>
          <Text style={{fontSize: 16, marginTop: 5, fontWeight: 'bold', opacity: 0.8}}>{item.name}</Text>
        </TouchableOpacity>
      )
    }



  return (
    <View style={{flex: 1, backgroundColor: '#fcfcfc'}}>
        <View style={{zIndex: 9000, position:'absolute'}}>
            <Header/>
        </View>
        <ScrollView style={{height: height}}>
        {/* hero */}
        <View style={{height: height/1.8, width: width, marginTop: 60, backgroundColor: '#edf1f2', flexDirection: 'row'}}>
          
          {/* {showBanner()} */}

          <ImageBackground style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}} 
            source={{uri: secImage}}
          >
              <View style={{alignItems: 'center'}}>
                <View style={{padding: 10, backgroundColor: ourOrange}}>
                <Text style={{fontSize: 35, fontWeight: 'bold', marginBottom: 10,color: '#fcfcfc'}}>EVENTS SOLD HOT</Text>
                </View>
                

                <TouchableOpacity onPress={()=> navigation.navigate('Search')}>
                  <View style={{height: 60, width: width / 3, backgroundColor: '#fcfcfc', borderRadius: 20, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 30}}>
                    <Feather name='search' size={30} color={'#d1d1d1'} />

                    <Text style={{marginLeft: 30, fontSize: 16, color: '#d1d1d1'}}>Search for an event</Text>
                  </View>
                </TouchableOpacity>
                
              </View>
          </ImageBackground>
          
        </View>



        <View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />
       
        {/* popular categories */}
        
        <Animated.View style={{}}>
          <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Popular Categories</Text>

          <Animated.View style={{marginTop: 20}}>

            <FlatList
              horizontal
              showsHorizontalScrollIndicator={true}
              style={{paddingLeft: 25}}
              data={categoryList}
              renderItem={renderCategory}
            />


         
          </Animated.View>



        </Animated.View>

        <View style={{height: 20, width: width, backgroundColor: '#edf1f2'}} />

        {/* <Animated.View style={{height: height / 3, width: width - 30, backgroundColor: '#edf1f2', alignSelf: 'center', padding: 10, alignItems: 'center', justifyContent: 'center', borderRadius: 20, marginTop: 30}}>
          <ImageBackground source={{uri: joyus}} style={{height: '98%', width: '98%'}} imageStyle={{borderRadius: 20}}>
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , borderRadius: 20, justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width / 1.5, padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>Joyus Celebration One Night</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Feather name='map' color={'white'} size={15} />
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 20}}>Matsulu Community Hall</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}} onPress={()=> navigation.navigate('EventDetails', {})}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>See Event</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
          </ImageBackground>
        </Animated.View> */}

        <View style={{flexDirection: 'row'}}>

        <View style={{width: '70%', backgroundColor: 'transparent', paddingBottom: 30 }}>

       

        <Animated.View style={{width :'100%'}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Trending in South Africa  <Image source={{uri: 'https://em-content.zobj.net/thumbs/160/whatsapp/116/flag-for-south-africa_1f1ff-1f1e6.png'}} style={{height: 20, width: 20}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secOne.slice(0,4)}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 10}}
          />
       
       
       
       
        </Animated.View>

        <Animated.View style={{width : '100%'}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Weekend Ziyawa<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secTwo.slice(0,4)}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 15}}
          />
       
       
       
       
        </Animated.View>


        </View>

        <View style={{width: '30%'}}>
          <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Live Streaming  <View style={{padding: 5, borderRadius: 5, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center'}}><Text style={{color: '#fcfcfc', fontSize: 11}}>LIVE</Text></View> </Text>

          <View style={{height: 360, width: '90%', backgroundColor: '#edf1f2', borderRadius: 20, marginTop: 15, alignItems: 'center', justifyContent: 'center'}}>
            <Video
              ref={video}
              style={{width: '90%'}}
              source={{
                uri: 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              isLooping
              onPlaybackStatusUpdate={status => setStatus(() => status)}
              onReadyForDisplay={videoData => {
                videoData.srcElement.style.position = "initial"
              }}
            />

            <Text style={{fontSize: 16, fontWeight: 'bold', marginTop: 10}}>Buggs Bunny Live Skit</Text>
            <Text style={{fontSize: 12}}>R50.00</Text>
          </View>

          <View style={{height: 360, width: '90%', backgroundColor: '#edf1f2', borderRadius: 20, marginTop: 15, alignItems: 'center', justifyContent: 'center'}}>
            <Video
              ref={video}
              style={{width: '90%'}}
              source={{
                uri: 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4',
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
              isLooping
              onPlaybackStatusUpdate={status => setStatus(() => status)}
              onReadyForDisplay={videoData => {
                videoData.srcElement.style.position = "initial"
              }}
            />

            <Text style={{fontSize: 16, fontWeight: 'bold', marginTop: 10}}>English HL Grade 12</Text>
            <Text style={{fontSize: 12}}>R20.00</Text>
          </View>

        </View>


        

        <View>

        </View>
        </View>

        <Animated.View style={{height: height / 3, width: width - 30, backgroundColor: '#edf1f2', alignSelf: 'center', padding: 10, alignItems: 'center', justifyContent: 'center', borderRadius: 20, marginTop: 30}}>
          <ImageBackground source={{uri: 'https://images.unsplash.com/photo-1687333517042-b1749fc15642?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'}} style={{height: '98%', width: '98%'}} imageStyle={{borderRadius: 20}}>
          <LinearGradient  
                style={{width: '100%', height: '100%', alignItems: 'center' , borderRadius: 20, justifyContent: 'center'}}
                colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0.6)']}>

                  <Animated.View style={{borderRadius: 10, backgroundColor: 'rgba(0,0,0,0.6)', width: width / 1.5, padding: 10, alignItems: 'center'}}>
                      <Text style={{fontWeight: 'bold', fontSize: 20, color: '#fcfcfc', alignSelf: 'center', textAlign: 'center'}}>Sell your event</Text>
                      <View style={{flexDirection: 'row', alignSelf: 'center', alignItems: 'center', marginTop: 10}}>
                        <Text style={{fontSize: 12, color: '#fcfcfc', marginLeft: 0}}>A simple guide on how to</Text>
                      </View>

                  
                      <Animated.View style={{height: 30, width: 100, backgroundColor: ourOrange, alignItems: 'center', justifyContent: 'center', borderRadius: 5, marginTop: 15, marginBottom: 25}}>
                        <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}} onPress={()=> navigation.navigate('EventDetails', {})}>
                          <Text style={{fontSize: 12, fontWeight: 'bold', color: '#fcfcfc', marginRight: 5}}>Watch Video</Text>
                          <Feather name='arrow-right' size={10} color={'#fcfcfc'} />
                        </TouchableOpacity>
                      </Animated.View>

                  </Animated.View>

                  

                </LinearGradient>
          </ImageBackground>
        </Animated.View>

        <Animated.View style={{width :width}}>

            <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>You might like<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>
       
          <FlatList 
            showsHorizontalScrollIndicator={false}
            horizontal
            data={secThree}
            renderItem={renderEventList}
            style={{marginTop: 15, paddingLeft: 15, marginBottom: 0}}
          />
       
       
       
       
        </Animated.View>

        {/* <Animated.View style={{width :width}}>

        <Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Gamer's Paradise<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>

        <FlatList 
        showsHorizontalScrollIndicator={false}
        horizontal
        data={eventList}
        renderItem={renderEventList}
        style={{marginTop: 15, paddingLeft: 15, marginBottom: 0}}
        />




        </Animated.View>

        <Animated.View style={{width :width}}>

<Text style={{fontWeight: 'bold', fontSize: 18, marginLeft: 15, marginTop: 25}}>Gauteng<Image source={{uri: 'https://em-content.zobj.net/source/microsoft-teams/363/man-dancing_1f57a.png'}} style={{height: 30, width: 30}}/> </Text>

<FlatList 
showsHorizontalScrollIndicator={false}
horizontal
data={eventList}
renderItem={renderEventList}
style={{marginTop: 15, paddingLeft: 15, marginBottom: 15}}
/>




        </Animated.View> */}

        <Animated.View style={{height: height / 3, width: width, backgroundColor: '#1e1f24', marginTop: 25, padding: 25}}>
            <View style={{}}>
              <Image source={require('./assets/nblogo.png')} style={{width: 80, height: 60, resizeMode: 'contain'}} />
            </View>

            <View style={{flexDirection: 'row', justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>About Us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>List your event</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Help</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>FAQs</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Ts n Cs</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>

            <View style={{flexDirection: 'row', marginTop: 25, justifyContent: 'space-evenly'}}>
              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Chat to us</Text>
              </TouchableOpacity>

              <TouchableOpacity style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Find Us</Text>
              </TouchableOpacity>

              <TouchableOpacity onPress={()=> navigation.navigate('Downloads')} style={{width: '35%'}}>
                <Text style={{fontSize: 12, color: '#fcfcfc'}}>Download Apps</Text>
              </TouchableOpacity>
            </View>
            
            <Text style={{fontSize: 12, color: '#fcfcfc', alignSelf: 'center', marginTop: 30}}>A product of REDSNAGISH (PTY)LTD</Text>
            <Text style={{fontSize: 10, color: '#fcfcfc', alignSelf: 'center', marginTop: 5}}>Developed by Sunday Creatives (Pty) Ltd</Text>
        </Animated.View>

        </ScrollView>


    </View>
  )
}

export default HomeMain

const styles = StyleSheet.create({
  dotView: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
  }
})