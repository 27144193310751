import {registerRootComponent} from 'expo';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, TextInput, TouchableOpacity, ActivityIndicator, ImageBackground, Animated, Image } from 'react-native';
import { useState, useEffect, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Plug from './Plug';
import Results from './Results';
import { useNavigation } from '@react-navigation/native';
import Details from './Details';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import {getUserData} from './src/graphql/queries';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import PaystackPop from '@paystack/inline-js'
import { LinearGradient  } from 'expo-linear-gradient';
import HomeMain from './HomeMain';
import EventDetails from './Screens/EventDetails';
import EventTickets from './Screens/EventTickets';
import MyTickets from './Screens/MyTickets';
import TicketDetails from './Screens/TicketDetails';
import Cart from './Screens/Cart';
import Payment from './Screens/Payment';
import CategorySearch from './Screens/CategorySearch';
import Downloads from './Screens/Downloads';
import * as Linking from 'expo-linking';

const {width, height} = Dimensions.get('screen')
const Stack = createNativeStackNavigator();
import { ContextProvider } from './src/contexts/ContextProvider';


import { Amplify } from 'aws-amplify';
import awsExports from './src/aws-exports';
import Profile from './Screens/Profile';
import Search from './Screens/Search';
Amplify.configure(awsExports);

function HomeScreen() {
    return (
      <View style={{ flex: 1, backgroundColor: '#f2f2f2', alignItems: 'center', justifyContent: 'center'}}>
       <Plug/>
      </View>
    );
  }


export default function App() {

  const [choose,setChoose] = useState('Main');
  const [emailAddress, setEmailAddress] = useState('');
  const [password ,setPassword] = useState('');
  const [idNumber ,setIdNumber] = useState('');
  const [userInfo, setUserInfo] = useState();
  const [spinning, setSpinning] = useState(false);
  const [errorMs ,setErrorMs] = useState('');
  const [secureEnt, setSecureEnt] = useState(true)

  

  useEffect(() => {

    if(width <= 750){
      Linking.openURL('http://m.ticketday.co.za')
      return
    }
    

    Animated.timing(opacityE, {
      toValue: 1,
      duration: 900,
      delay: 350,
      useNativeDriver: true,
    }).start();

    Animated.timing(opacityF, {
      toValue: 1,
      duration: 900,
      delay: 1500,
      useNativeDriver: true,
    }).start();


    setTimeout(() => {
      setChoose('done')
    }, 6000);



  }, [])
  
 

  const ExplTextY = useRef(new Animated.Value(height)).current; 
const ourOrange = '#FF4F00';
const opacityA = useRef(new Animated.Value(0)).current;
    const opacityB = useRef(new Animated.Value(0)).current;
    const opacityE = useRef(new Animated.Value(0)).current;
    const opacityF = useRef(new Animated.Value(0)).current;


  if(choose === 'Main'){


      return(
        <Animated.View style={{flex: 1, backgroundColor: '#fcfcfc', alignItems: 'center', justifyContent: 'center', opacity: opacityE}}>
               <Image source={require('./assets/dar.png')} style={{width: width / 2, height: 60, resizeMode: 'contain'}} /> 
               <Animated.View style={{opacity: opacityF}}>
                 <ActivityIndicator size={'small'} color={'purple'} style={{marginTop: 10}} />
               </Animated.View>
               
        </Animated.View>
      )
  }


  const config = {
    screens: {
      EventTickets: {
        path: ' EventTickets/:id',
        parse: {
          id: (id)=> ` EventTickets-${id}`,
        },
        stringify: {
          id: (id) => id.replace(/^EventTickets-/, '')
        }
      }
    }
  }

  const deepLinking = {
   
    // prefixes: ['https://ticketday.co.za/', 'https://*.ticketday.co.za'],
      // config: {
      //   screens: {
      //     EventTickets: ':id'
      //   }
      // },
      config
  }
  

  
  
  return (
    <ContextProvider>
      <View style={{flex: 1}}>

      
      
<NavigationContainer
 
  linking={deepLinking}
  // fallback={<Text>Loading...</Text>}
>
<Stack.Navigator screenOptions={{
   headerShown: false,
}}

initialRouteName="Home">
  <Stack.Screen name="Home" component={HomeMain} />
  <Stack.Screen name="Search" component={Search} />
  <Stack.Screen name="Profile" component={Profile} />
  <Stack.Screen name="EventDetails" component={EventDetails} />
  <Stack.Screen name="EventTickets" component={EventTickets} />
  <Stack.Screen name="MyTickets" component={MyTickets} />
  <Stack.Screen name="TicketDetails" component={TicketDetails} />
  <Stack.Screen name="Cart" component={Cart} />
  <Stack.Screen name="Payment" component={Payment} />
  <Stack.Screen name="CategorySearch" component={CategorySearch} />
  <Stack.Screen name="Downloads" component={Downloads} />
</Stack.Navigator>
</NavigationContainer>
      </View>
    </ContextProvider>
    
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
